import React, { useState, Fragment } from "react";
import { Menu, MenuItem, IconButton, ListItemIcon, Typography } from "@mui/material";
import { Translate } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

// Sample countries list
let countries = [
  {
    code: "ar",
    name: "عربي",
    country_code: "ar",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "cn",
    name: "中国人",
    country_code: "cn",
  }
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div aria-label="usermenu" className="float-start" style={{marginTop:"-8px"}} >
      <IconButton
        onClick={handleClick}
        size="large"
        aria-label="usermenu-button"
        aria-controls="language-menu"
        aria-haspopup="true"
        color="inherit"
      >
        <Translate className="text-blue-600" />
      </IconButton>

      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {  boxShadow: '0px 5px 15px rgba(0,0,0,0.1)' },
        }}
      >
        {countries.map((lng) => (
          <MenuItem
            key={lng.code}
            onClick={() => {
              i18n.changeLanguage(lng.code);
              handleClose();
            }}
            disabled={i18n.language === lng.code}
          >
            <ListItemIcon>
              <span className={`fi fi-${lng.country_code}`} />
            </ListItemIcon>
            <Typography variant="inherit">{lng.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageSelector;
