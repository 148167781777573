import logo from './logo.svg';
import { Navbar ,Col } from 'react-bootstrap';
import "./i18n";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { faCalculator, faSignInAlt ,faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserContext from './UserContext';
import {Login} from './widgets/Login';
import Test from './widgets/Test';

import {NewOrder} from './widgets/NewOrder';
import 'bootstrap/dist/css/bootstrap.rtl.css';
import "@fontsource/cairo/index.css";
import "@fontsource/tajawal/index.css";
import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/js/bootstrap.min.js';

    
import './App.css';
import { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';

function App() {
  const [isLogin,setIsLogin]= useState(false);
  const [screen,setScreen]= useState('');
  useEffect(()=>{
   
    if(localStorage.getItem("isLogin") != null){
      var loginValue =   !(localStorage.getItem("isLogin")== "false");
        setIsLogin(loginValue);
    
        }
    //console.log(localStorage);
    //console.log("Login => "+isLogin);
    //console.log("loginValue => "+loginValue);

    if(localStorage.getItem("screen") != undefined)setScreen(localStorage.getItem("screen"));
  },[]);
const handlerLogout=()=>{
    localStorage.setItem("isLogin",false) ;
    setIsLogin(false);
}

  return (
    <>
    <div className="App" dir='rtl'>
    <UserContext.Provider value={{isLogin,setIsLogin}}>

      <Navbar expand="lg" bg="barsell" variant="barsell">
        <Navbar.Brand className=' w-100 text-center '>
        <a href='/calculator' className='text-white'  >

        <FontAwesomeIcon className="float-start pt-1" icon={faCalculator} style={{paddingRight:"8px"}}/>
    </a>
            بارسلـ.ـكوم
          {isLogin &&   
          <a onClick={handlerLogout} >
          <FontAwesomeIcon className="float-end pt-1" icon={faPowerOff} />
          </a>
            }
        </Navbar.Brand>
      </Navbar>
      <div className="container">
        <div className="wizard my-5">
            
            <div className="tab-content" id="myTabContent">
            {isLogin == false  && 
            <>
            <Login />
            </>}  
            { isLogin == true && <>
                {(screen == "" || screen =="new-order") && 
                    <>
                        <NewOrder />
                   
                    </>
                
                    
                }  
            
            </>}
       
                <div className="tab-pane fade  " role="tabpanel" id="step2" aria-labelledby="step2-tab">
                
                </div>
               <div className="tab-pane fade " role="tabpanel" id="step3" aria-labelledby="step3-tab">
                    <h2 className="text-center mt-4">أضافة المنتجات </h2>

                    <div className="card mt-4 mb-3">
                        <div className="card-header">
                          المنتجات
                        </div>
                        <div className="card-body" >
                        <div className="products-area">
                            <div className="intro_products_area">
                                لم تقم بأضافة أي منتجات لشحنها حتى الأن بأمكانك البدء عبر الضغط بزر أضافة منتج ......
                            </div>        
                        </div>
                        <hr />
                          <button type="button" className="btn btn-primary " data-bs-toggle="modal" data-bs-target="#add_product">
                            أضافة منتج
                          </button>
                        </div>
                      </div>
                 
                    
                    <div className="modal fade" id="add_product" tabIndex="-1" role="dialog" aria-labelledby="addProductTitle" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">بيانات المنتج</h5>
                                        <button type="button" className="close btn btn-danger mr-auto" data-bs-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                </div>
                                <div className="modal-body">
                                    <div className="mt-4 mb-2">
                                                <input type="hidden" value="0" name="product-id" id="product-id" />
                                                <div className="form-group">
                                                  <label htmlFor="product-name">اسم المنتج </label>
                                                  <input type="text" name="product-name" id="product-name" className="form-control" placeholder="" aria-describedby="product-name" />
                                                  <small id="product-name" className="text-muted">اكتب اسم المنتج  باللغة الأنجليزية</small>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="product-hs-code">HS Code <small className="badge bg-danger text-10px text-small text-truncate" >قم بطلبه من البائع</small>  </label>
                                                    <input type="text" name="product-hs-code" id="product-hs-code" className="form-control" placeholder="" aria-describedby="product-hs-code" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="product-url">رابط المنتج</label>
                                                    <input type="text" name="product-url" id="product-url" className="form-control" placeholder="" aria-describedby="product-url"/>
                                                    <small id="product-url" className="text-muted">قم بوضع رابط المنتج</small>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product-quantity">عدد القطع</label>
                                                            <input type="number" name="product-quantity" id="product-quantity" className="form-control" placeholder="" aria-describedby="product-hs-code" />
                                                            <small id="product-quantity" className="text-muted">    </small>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label htmlFor="product-unit-price">سعر القطعة</label>
                                                            <input type="numver" name="product-unit-price" id="product-unit-price" className="form-control" placeholder="" aria-describedby="product-hs-code"/>
                                                        </div>
                                                        
                                                    </div>
                                                </div>      
                                               
                                                <div className="form-group mt-3 mb-2">
                                                  <label htmlFor="">صورة المنتج </label>
                                                  <input type="file" className="form-control-file" name="" id="" placeholder="" aria-describedby="product_file" />
                                                  <small id="product_file" className="form-text text-muted">قم برفع صورة للمنتج </small>
                                                </div>                 
                                             
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">إلغاء</button>
                                    <button type="button" className="btn btn-primary" id="btn-add-product">إضافة</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <a className="btn btn-secondary previous"><i className="fas fa-angle-right"></i> السابق</a>
                        <a className="btn btn-primary next">التالي <i className="fas fa-angle-left"></i></a>
                    </div>
                </div>
                <div className="tab-pane fade   " role="tabpanel" id="step4" aria-labelledby="step4-tab">
                    <h3 className="d-block text-center mt-3">تم رفع طلبك</h3>
                    <p className="text-center">متشوقين لاستقبال شحنتك في مستودعاتنا   </p>
                   <div className="copy_box">
                    <div className="text-wrap">
                        <textarea id="textA" className="w-100" dir="ltr" defaultValue={`
                            广东省白云区凤鸣路120号a7仓(OM-BAR)
联系人: JACKSON : 13710722345
邮政编码: 510550
联系人: TAMEEM : 19066500477
邮政编码: 510550

防范措施：
1. 请务必询问他并在所有方框中写下此客户运输标记。否则不予受理。
2、请携带入库单复印件两份。入库前请提前与仓管员预约并告知发货时间。谢谢您的合作。
3.休息时间：周六下午2点至周日
4、因广州物流管控组通知，请积极配合仓管对货物进行拆箱检查并拍照上传。谢谢。
5、请积极配合仓管拆箱检查货物并上传照片。谢谢。
6、仓库工作及收货时间：周一至周六：9:00-19:30；周六 9:00-14:00
  节假日期间，请提前与销售人员预约并确认发货时间。
7、如果货物采用木托盘包装，请提前告知。
8、仓库不承担运费和货款。
9、严禁将任何易燃、易爆物品及中华人民共和国禁止出口的各类危险品带入仓库！`}>
                        </textarea>
                        <div className="copy-button badge " id="copyToClipboard-a"><i className="fa fa-clipboard"></i>نسخ</div>
                        <div id="d-a" className="clipboard icon"></div>
                      </div>
                   </div>
                    
                    
                    <div className="d-flex justify-content-between">
                        <a className="btn btn-primary m-auto next">تتبع طلبك <i className="fas fa-truck"></i></a>
                    </div>
                </div>
            </div> 
        </div>
    </div>
   
  
        </UserContext.Provider>

    </div>
    </>
  );
}

export default App;
