  import axios from 'axios';
  import Notify from './components/Notify';
  export const API_URL = 'https://api.bar-sell.com/api'; // Replace with your API URL
  axios.defaults.headers.common['X-USER-TOKEN'] = localStorage.getItem('token');
  axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');

  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.interceptors.request.use(function (config) {
    let token = localStorage.getItem('x-auth-token')  ;
    
    if (token) {
      config.headers['X-AUTH-TOKEN'] = token;

    }
    token = localStorage.getItem('x-user-token')  ;
    
    if (token) {
      config.headers['X-USER-TOKEN'] = token;

    }
    return config;
    }, function (error) {
    return Promise.reject(error);
  });
  /********* Shipping Method ************/
  export const apiUploadImage = (img) => axios.patch(`${API_URL}/client/upload-image`,img,{headers: {
      'Content-Type': 'multipart/form-data',
      'X-USER-TOKEN':localStorage.getItem('token')
    }});

// Function to catch errors for API calls
const catchError = async (fn) => {
  try {
    const res = await fn();  // Await the function call
    return res;  // Return the response
  } catch (error) {
    // Display error notification
    Notify({ message: error.message, type: 'error' });
    console.error('API error:', error);  // Optionally log the error to console
    // Optionally, return something meaningful when an error occurs
    return null;  // You could return `null` or some default value if needed
  }
};

// Use the `catchError` function for your API calls
export const getCategories = async () => catchError(() => axios.get(`${API_URL}/client/category`));
export const updateShippingMethod = async (id, data) => catchError(() => axios.put(`${API_URL}/client/shipping-methods/${id}`, data));
export const addOrder = async (id, data, currency) => catchError(() => axios.put(`${API_URL}/frontend/${id}/add-order/${currency}`, data));
export const deleteShippingMethod = async (id) => catchError(() => axios.delete(`${API_URL}/client/shipping-methods/${id}`));
export const getPrices = async () => catchError(() => axios.get(`${API_URL}/frontend/prices`));
export const getType = async () => catchError(() => axios.get(`${API_URL}/frontend/get-type`));
export const loginApi = async (data) => catchError(() => axios.post(`${API_URL}/login`, data));
export const phoneLogin = async (data) => catchError(() => axios.post(`${API_URL}/phone-login`, data));
export const checkotp = async (data) => catchError(() => axios.post(`${API_URL}/check-otp`, data));
export const chkOrder = async (data) => catchError(() => axios.post(`${API_URL}/frontend/chk-order`, data));
export const getOrder = async (id) => catchError(() => axios.get(`${API_URL}/frontend/order/${id}`));
export const upOrder = async (data) => catchError(() => axios.post(`${API_URL}/frontend/up-order`, data));
export const searchOrder = async (txt) => catchError(() => axios.get(`${API_URL}/frontend/search-order?txt=${txt}`));
export const trackingApi = async (oid) => catchError(() => axios.get(`${API_URL}/frontend/tracking/${oid}`));
export const updateOrder = async (id, data) => await axios.patch(`${API_URL}/order/update/${id}`, data);

export const upNewOrder = async (id, data) => await axios.patch(`${API_URL}/frontend/new-order/${id}`, data);
