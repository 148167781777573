// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-edit {
    position: relative;
}

.image-edit input[type="file"] {
    display: none;
}
.image-edit label {
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 17px;
    top: -5px;
    left: 25px;
    background: var(--bs-barsell);
    color: white;
    position: absolute;
}

.image-preview {
    margin-top: 10px;
}

.image-preview-image {
    width: 100px;
    height: 100px;
    border-radius: 10%;
    background-size: cover;
    background-position: center;
    border: 2px solid #ddd;
    transition: opacity 0.65s;
}`, "",{"version":3,"sources":["webpack://./src/components/ImageUploader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,SAAS;IACT,UAAU;IACV,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,2BAA2B;IAC3B,sBAAsB;IACtB,yBAAyB;AAC7B","sourcesContent":[".image-upload {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.image-edit {\n    position: relative;\n}\n\n.image-edit input[type=\"file\"] {\n    display: none;\n}\n.image-edit label {\n    cursor: pointer;\n    padding: 10px;\n    border-radius: 50%;\n    width: 35px;\n    height: 35px;\n    text-align: center;\n    line-height: 17px;\n    top: -5px;\n    left: 25px;\n    background: var(--bs-barsell);\n    color: white;\n    position: absolute;\n}\n\n.image-preview {\n    margin-top: 10px;\n}\n\n.image-preview-image {\n    width: 100px;\n    height: 100px;\n    border-radius: 10%;\n    background-size: cover;\n    background-position: center;\n    border: 2px solid #ddd;\n    transition: opacity 0.65s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
