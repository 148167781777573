import { Navbar ,Modal ,Button,Row,Col} from 'react-bootstrap';
import { ToastContainer, toast,Bounce } from 'react-toastify';
import { faAngleLeft,faAngleRight, faPencilSquare ,faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect ,useState ,useRef, createContext, useMemo, useContext } from 'react';
import Select from 'react-select'
import  {getCategories,addOrder} from '../api';
import ImageUploader from '../components/ImageUploader';
import Notify from '../components/Notify';
import UserContext from '../UserContext';

export const ImageInfo = createContext();
export const OrderInfo = createContext();

export const NewOrder = ()=>{

    const [screen,setScreen] = useState('');
    const [loading,setLoading] = useState(false);

    const [subScreen , setSubScreen] = useState('');
    const [clientID , setClientID] = useState('');
    const [products ,  setProducts]= useState([]);
    const [productName , setProductName]= useState();
    const [hsCode , setHsCode]= useState();
    const [productUrl , setProductUrl]= useState();
    const [Pices  , setPices]= useState();
    const [Price , setPrice]= useState();
    const [productImage , setProductImage]= useState();
    const [errProductName , setErrProductName]= useState();
    const [selectedCategory ,setSelectedCategory] =useState();
    const [errHsCode , setErrHsCode]= useState();
    const [errProductUrl , setErrProductUrl]= useState();
    const [errPices  , setErrPices]= useState(1);
    const [errPrice , setErrPrice]= useState();
    const [errProductImage , setErrProductImage]= useState();
    const [errImage, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [totalPrice,setTotalPrice]  = useState();
    const [totalPices,setTotalPices]  = useState();
    const [productImageUrl,setProductImageUrl]  = useState();
    const [errProductImageUrl,setErrProductImageUrl]  = useState();
    const [productMapKey,setProductMapKey]  = useState();
    const [orderID , setOrderId] = useState();
    const textareaRef = useRef(null); // Create a ref to access the textarea element
    const {isLogin, setIsLogin} = useContext(UserContext);   
    const [currency, setCurrency] = useState("$");   
    const [order  , setOrder] = useState({id:"",token:""});

    // Function to copy text to clipboard
    const copyToClipboard = () => {
      console.log(textareaRef.current);
        if (textareaRef.current) {
            
            navigator.clipboard.writeText(textareaRef.current).then(() => {
             Notify({message:"تم نسخ النص",type:"info"})
            }).catch(err => {
              Notify({message:"فشل في نسخ النص",type:"error"})

            });
            
        }
    };
const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);    
  
    useEffect(()=>{
        setScreen(localStorage.getItem('screen'));
        setClientID(localStorage.userID);
        setSubScreen((localStorage.getItem('subScreen') == undefined)?'':localStorage.getItem('subScreen'));
        setProducts(JSON.parse(localStorage.getItem('products_order')));
        console.log( localStorage.getItem('products_order')      );
        fetchCategories();
        textareaRef.current = `尊敬的卖家，请点击以下链接添加产品详细信息。
https://app.bar-sell.com/${localStorage.userID}/new-order
然后通知我您已完成。`;
    },[]);    
    useEffect(()=>{
  localStorage.setItem('screen',screen);


    },[screen]);
    const [categories, setCategories] = useState([]);
  
const fetchCategories= async()=>{
  var r = await getCategories();
  r =r.data.map((e) => 
    e.description?.split(',').map(label => ({ value: e._id+"_"+label, label }))
  ).flat().filter(Boolean);
  setCategories(  r);
  
}

  
    useEffect(()=>{
     if(productName != "") setErrProductName("");
    },[productName])
    useEffect(()=>{
      if(Pices != "") setErrPices("");
     },[Pices])
     useEffect(()=>{
      if(Price != "") setErrPrice("");
     },[Price])
     useEffect(()=>{
      if(productUrl != "") setErrProductUrl("");
     },[productUrl])
    useEffect(()=>{
        localStorage.setItem('subScreen',subScreen);
          },[subScreen]);
          const handleAddProduct = () => {
          var allerr = 0;
            if(productName == undefined || productName.trim()== ""){
              setErrProductName("يجب ان تقوم بكتابة اسم المنتج")
              allerr++;
            }
            
            if(isNaN(Pices) || Pices == ""){
              setErrPices("عدد القطع ضروري");
              allerr++;
            }
            if(isNaN(Price) || Price == ""){
              setErrPrice("سعر المنتج ");
              allerr++;
            }
            /*if(productImageUrl == undefined || productImageUrl.trim()== ""  ){
              setErrProductImageUrl(" صورة المنتج ضرورية ");
              allerr++;
            }*/
            if(allerr == 0) {
              
            if(productMapKey != undefined ) {

              setProducts(products.map(product =>
                product.id === productMapKey
                    ? { ...product, 
                      "productName":productName,
                      "hsCode":hsCode,
                      "price":Price,
                      "pices":Pices,
                      "productUrl":productUrl,
                      "productImageUrl":productImageUrl,
                      "category":selectedCategory
                    }   : product
            ));
            }else{
              setProducts([...products, {
                id:products.length,
                "productName":productName,
                "hsCode":hsCode,
                "price":Price,
                "pices":Pices,
                "productUrl":productUrl,
                "productImageUrl":productImageUrl,
                "category":selectedCategory
              }]);
            }
            resetElemnt();
        

              setShow();
              
            } 
          };
const resetElemnt=()=>{
  setProductName('');
  setProductMapKey(undefined);
  setSelectedCategory({});
  setProductName('');
  setHsCode('');
  setPices('');
  setPrice('');
  setProductUrl('');
  setProductImageUrl('');
}
  useEffect(()=>{
    console.log("Products Now is :"+products.length)

    setTotalPrice( products.map((_pr) => (_pr.pices * _pr.price))
    .reduce((prev, curr) => prev + curr, 0));
    setTotalPices( products.map((_pr) => (_pr.pices*1))
    .reduce((prev, curr) => prev + curr, 0));
  localStorage.setItem("products_order",JSON.stringify(products));
  },[products]);
  const editProduct = (e)=>{
    if(!isNaN(e)){
      console.log(products);
      setProductMapKey(e);
    console.log("Product Key :"+e);
    console.log(products);
    setProductName(products[e].productName);
    setHsCode(products[e].hsCode);
    setPices(products[e].pices);
    setSelectedCategory(products[e].selectedCategory);

    setPrice(products[e].price);
    setProductUrl(products[e].productUrl);
    setProductImageUrl(products[e].productImageUrl);
    setProductMapKey(e);
setShow(true);
    }
  }

  const dropProduct = (e)=>{
    if(!isNaN(e)){
      setProducts(products.filter(a => e!= a.id));
     
    }
  }
  const resetProductsArea=()=>{
    setProducts([]);
  }
  const upOrder=async()=>{

    if(products.length < 1){
      toast.error("يجب أضافة منتج علاقل", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });  
    }else{

setLoading(true);

const raw = localStorage.getItem('products_order');
try {
  let res = await addOrder(clientID,raw,currency);

  Notify({message:res.data.message,type:res.data.status});
  setOrderId(res.data.order[0]._id);
  setOrder({id:res.data.order[0]._id,token:res.data.order[0].token})

  textareaRef.current = `广东省白云区凤鸣路120号a7仓(OM-BAR)
联系人: JACKSON : 13710722345
邮政编码: 510550
联系人: TAMEEM : 19066500477
邮政编码: 510550

防范措施：
1. 请务必询问他并在所有方框中写下此客户运输标记。否则不予受理。
2.在箱子上贴上标签后，请通过以下链接(<a href="https://app.bar-sell.com/seller/${res.data.order[0]._id}/${res.data.order[0].token}>app.bar-sell.com/seller/${res.data.order[0]._id}/${res.data.order[0].token}</a>添加新的数据，如追踪号码和箱子数量。否则不予受理。
2、请携带入库单复印件两份。入库前请提前与仓管员预约并告知发货时间。谢谢您的合作。
3.休息时间：周六下午2点至周日
4、因广州物流管控组通知，请积极配合仓管对货物进行拆箱检查并拍照上传。谢谢。
5、请积极配合仓管拆箱检查货物并上传照片。谢谢。
6、仓库工作及收货时间：周一至周六：9:00-19:30；周六 9:00-14:00
  节假日期间，请提前与销售人员预约并确认发货时间。
7、如果货物采用木托盘包装，请提前告知。
8、仓库不承担运费和货款。
9、严禁将任何易燃、易爆物品及中华人民共和国禁止出口的各类危险品带入仓库！`;
  setSubScreen('orderDetails');
  resetElemnt();
  setProducts([]);

} catch (error) {
  Notify({message:error.message,type:'error'})      
}


setLoading(false);

    }
  }
    return (
      
      <>
      {(loading)?(<div class="spinner-border text-success d-block m-auto" role="status">
            <span class="sr-only">Loading...</span>
          </div>):(<>
      {subScreen === "" && (
        
        <>
             <>
             <h5>الشحن</h5>

            <div className='d-flex flex-wrap'>
            <a className="btn btn-barsell next m-auto mt-3" onClick={() => { 
              setSubScreen('addProduct'); 
              localStorage.setItem('subScreen', 'addProduct'); 
            }}><i  className="d-block fa fa-cart-arrow-down" />
             <small> طلب جديد </small>
            </a>
            <a className="btn btn-barsell next m-auto mt-3" href='/calculator'>
            <i  className="d-block fa fa-calculator" />
             <small> حاسبة الشحن</small>
            </a>
          
           
         
            </div>
            <hr />
            <h5>الدفع</h5>
            <div className='d-flex flex-wrap'>
            <a className="btn btn-primary next m-auto mt-3" href='https://bar-sell.com/product/%d8%b4%d8%ad%d9%86-%d8%ac%d9%88%d9%8a/'>
            <i  className="d-block fa fa-plane" />
             <small>شحن جوي</small>
            </a>
            <a className="btn btn-primary next m-auto mt-3" href='https://bar-sell.com/product/%d8%b4%d8%ad%d9%86-%d8%a8%d8%ad%d8%b1%d9%8a-%d9%86%d8%b3%d8%ae%d8%a9/'>
            <i  className="d-block fa fa-ship" />
             <small>شحن بحري</small>
            </a>
            </div>
            <hr />
            <div className='d-flex flex-wrap'>
        
            <a className="btn btn-danger next m-auto mt-3" onClick={() => { 
              setIsLogin(false);
              
            }}>
              <i  className="d-block fa fa-power-off" />
              <small> تسجيل الخروج </small>
            </a>
          
            </div>
            <div style={{display:"block",marginTop:"80px",fontSize:"x-large"
           }}> <p variant='p' style={{display:"block",textAlign:"center"}}  >Shipping Code 
           <code style={{display:"block",textAlign:"center",fontweight:"bold"}}>BAR-A/S-{clientID}</code></p></div>
 <div className="d-none">
                    <h3 className="d-block text-center mt-3">  لرفع  طلبك لدينا قم بأرسال رابط أضافة المنتجات الخاصة بك للبائع</h3>
                   <div className="copy_box">
                    <div className="text-wrap">
                        <textarea disabled id="textA" style={{minHeight:"100px"}}
                         className="w-100" dir="ltr" defaultValue={textareaRef.current}>
                        </textarea>

                        <div className="copy-button badge " onClick={copyToClipboard} id="copyToClipboard-a"><i className="fa fa-clipboard"></i>نسخ</div>
                        <div id="d-a" className="clipboard icon" onClick={copyToClipboard}></div>
                      </div>
                   </div>
                    
                   <p className="text-center">متشوقين لاستقبال شحنتك في مستودعاتنا   </p>

                
                </div>        
          </>
        </>
      )}

      {subScreen === "options" && (
        
        <>
          <div className="mt-4">
            <h2 className="text-center">معلومات الشحنة</h2>
            <div className="form-group">
              <label htmlFor="" className="m-1">المنتجات تم طلبها من :</label>
              <div className="btn-group w-100 m-1" data-toggle="buttons">
                <label className="btn btn-info">
                  <input type="radio" name="radio-vendor" id="radio-vendor1" autoComplete="off" checked /> بائع واحد
                </label>
                <label className="btn btn-info">
                  <input type="radio" name="radio-vendor" id="radio-vendor2" autoComplete="off" /> اكثر من بائع
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <a className="btn btn-barsell next m-auto mt-3" onClick={() => { 
              setSubScreen('addProduct'); 
              localStorage.setItem('subScreen', 'addProduct'); 
            }}>
              إضافة المنتجات <FontAwesomeIcon icon={faAngleLeft} />
            </a>
          </div>
        </>
      )}

      {subScreen === "addProduct" && (
        <>
          <div className="" aria-labelledby="step3-tab">
            <h2 className="text-center mt-4">أضافة المنتجات </h2>
            <div className="card mt-4 mb-3">
              <div className="card-header">المنتجات <FontAwesomeIcon icon={faTrash} className="text-danger float-end" onClick={resetProductsArea}></FontAwesomeIcon></div>
              <div className="card-body">
                <div className="products-area">
                  {products === undefined  || products.length === 0 && (
                    <div className="intro_products_area">
                      لم تقم بأضافة أي منتجات لشحنها حتى الأن بأمكانك البدء عبر الضغط بزر أضافة منتج ......
                
                    </div>
                  )}
                  
                  {(products != undefined  &&  products.length > 0) && (
                    <>
                      {products.map((_product, i) => (
                        <>
                        
                        <Row key={i}>
                        <Col xs="1"><FontAwesomeIcon   onClick={(i)=>{editProduct(_product.id);}} icon={faPencilSquare} className="text-barsell"></FontAwesomeIcon>
                        <br />
                        <FontAwesomeIcon onClick={()=>{dropProduct(_product.id)}} icon={faTrash} className="text-danger"></FontAwesomeIcon>
                        </Col>

                        <Col >
                        {_product.productName}
                        <br/>
                        {_product.price}x{_product.pices}

                        </Col>
                        <Col className='text-left'>
                        {_product.price * _product.pices} {currency}
                        </Col>
                        </Row>
                        <hr />
                        </>

                      ))}
                      
                      <Row>
                        <Col>{totalPices} قطعة</Col>
                        <Col className='text-left'>{totalPrice}
                        <select class="form-select-sm" value={currency} onChange={e=>setCurrency(e.target.value)}><option value={"$"}>$</option><option value={"RMB"}>RMB</option></select>
                        </Col>
                      </Row>
                      </>
                  )}
                </div>
                
                <hr />
                <button type="button" onClick={handleShow} className="btn btn-barsell" >
                  أضافة منتج
                </button>
              </div>
            </div>
                  <Modal show={show} onHide={handleClose} animation={false}>
                  <Modal.Header closeButton >
                    <span>     بيانات المنتج  </span>
                    </Modal.Header>                  
                  <Modal.Body>
                  <div className="mt-4 mb-2">
                      <input type="hidden" value={productMapKey} name="product-id" id="product-id" />
                      <div className="form-group">
                        <label htmlFor="product-name">اسم المنتج </label>
                        <input value={productName} onChange={(e) => setProductName(e.target.value)} type="text" name="product-name" id="product-name" className="form-control" placeholder="" aria-describedby="product-name" />
                        <small id="product-name" className="text-danger">{errProductName}</small>
                      </div>
                      <div className="form-group">
                        <label htmlFor="product-name">نوع المنتج </label>
                        <Select options={categories}  value = {
       categories.filter(option => 
          option === selectedCategory)
    }
    onChange = {value =>setSelectedCategory(value)}/>
                        <small id="product-name" className="text-danger">{errProductName}</small>
                      </div>
                     
                      <div className="form-group">
                        <label htmlFor="product-hs-code">HS Code <small className="badge bg-danger text-10px text-small text-truncate">قم بطلبه من البائع</small></label>
                        <input value={hsCode} onChange={(e) => setHsCode(e.target.value)} type="text" name="product-hs-code" id="product-hs-code" className="form-control" placeholder="" aria-describedby="product-hs-code" />
                      </div>
                      

                    
                      <div className="form-group">
                        <label htmlFor="product-url">رابط المنتج</label>
                        <input value={productUrl}  onChange={(e) => setProductUrl(e.target.value)} type="text" name="product-url" id="product-url" className="form-control" placeholder="" aria-describedby="product-url" />
                        <small id="product-url" className="text-danger">{errProductUrl}</small>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="product-quantity">عدد القطع</label>
                            <input value={Pices} onChange={(e) => setPices(e.target.value)} type="number" name="product-quantity" id="product-quantity" className="form-control" placeholder="" aria-describedby="product-hs-code" />
                             <small id="Pices" className="text-danger">{errPices}</small>

                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="product-unit-price">سعر القطعة</label>
                            <input value={Price} onChange={(e) => setPrice(e.target.value)} type="number" name="product-unit-price" id="product-unit-price" className="form-control" placeholder="" aria-describedby="product-hs-code" />
                            <small id="Price" className="text-danger">{errPrice}</small>

                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-3 mb-2">
                        <label htmlFor="">صورة المنتج </label>
                        <ImageInfo.Provider  value={{productImageUrl , setProductImageUrl}}>
                        <ImageUploader />
                        </ImageInfo.Provider>
                        <input type="hidden" value={productImageUrl} />
                        <small id="productImageUrlErr" className="text-danger">{errProductImageUrl}</small>

                     
                      </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          إلغاء
          </Button>
          <Button variant="barsell" onClick={handleAddProduct}>
          إضافة
          </Button>
        </Modal.Footer>
                
          
              </Modal>

            <div className="d-flex justify-content-between">
              <a className="btn btn-secondary previous" onClick={() => setSubScreen('')}>
                <FontAwesomeIcon icon={faAngleRight} /> السابق
              </a>
              <a className="btn btn-barsell next" onClick={upOrder}>إتمام الطلب <FontAwesomeIcon icon={faAngleLeft} /></a>
            </div>
          </div>
        </>
      )}
        {subScreen === "orderDetails" && (
        <>
 <div className="">
                    <h3 className="d-block text-center mt-3">تم رفع طلبك</h3>
                    <h5><b>رقم الطلب : </b>#<span className='text-danger'>{orderID}</span></h5>
                    <p className="text-center">متشوقين لاستقبال شحنتك في مستودعاتنا   </p>
                   <div className="copy_box">
                    <div className="text-wrap">
                        <textarea  id="textA" className="w-100" dir="ltr" defaultValue={textareaRef.current}>
                        </textarea>
                        <div className="copy-button badge " onClick={copyToClipboard} id="copyToClipboard-a"><i className="fa fa-clipboard"></i>نسخ</div>
                        <div id="d-a" className="clipboard icon" onClick={copyToClipboard}></div>
                      </div>
                   </div>
                    
                    
                    <div className="d-flex justify-content-between">
                        <a className="btn btn-barsell m-auto next">تتبع طلبك <i className="fa fa-truck"></i></a>
                        <a className="btn btn-barsell m-auto next" onClick={()=>{setSubScreen('')}}> شحنة جديد<i className="fa fa-truck"></i></a>

                    </div>
                </div>        
          </>
      )}
      </>
    )}
    </>
    )
}
