import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function SitemarkIcon() {
  return (
    <SvgIcon viewBox="47.6187 102.1356 413.8536 181.2" xmlns="http://www.w3.org/2000/svg" sx={{m:"auto" , width:"10rem",height:"4rem"}}>
  <path d="M4050 1586 l0 -605 55 19 c156 54 297 163 358 277 34 65 67 190 67&#10;257 -1 116 -48 251 -125 356 -67 91 -200 208 -317 279 l-38 23 0 -606z" 
fill="rgb(6, 127, 174)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)"/>
  <path d="M4645 1112 c-78 -58 -283 -168 -386 -209 -57 -23 -155 -53 -217 -69&#10;l-112 -27 0 -214 0 -213 28 6 c105 25 310 121 420 197 189 130 320 345 310&#10;508 l-3 50 -40 -29z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)"/>
  
  <path d="M1504 1696 c-62 -28 -82 -91 -44 -139 11 -14 46 -35 78 -47 70 -26&#10;82 -36 82 -65 0 -45 -60 -65 -128 -42 -23 8 -42 12 -42 8 0 -3 -3 -16 -6 -27&#10;-5 -19 0 -23 45 -34 94 -22 174 9 187 74 8 44 -16 90 -57 108 -122 51 -146 80&#10;-94 114 22 14 33 15 73 6 26 -6 48 -10 48 -9 12 16 12 46 -1 50 -38 15 -112&#10;17 -141 3z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>S</title>
  </path>
  <path d="M2582 1700 c-62 -14 -105 -71 -116 -150 -6 -44 -6 -45 29 -61 28 -13&#10;35 -14 35 -3 0 8 -4 14 -9 14 -10 0 1 51 21 103 19 48 84 70 143 47 22 -8 27&#10;-7 34 13 8 19 4 23 -28 34 -42 13 -63 14 -109 3z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>C</title>
  </path>
  <path d="M2834 1700 c-42 -13 -91 -70 -100 -115 -10 -58 1 -143 22 -172 39&#10;-52 71 -68 134 -68 64 0 95 16 134 69 17 23 21 42 21 111 0 89 -11 117 -66&#10;157 -29 22 -103 31 -145 18z m105 -54 c36 -19 51 -55 51 -121 0 -66 -15 -102&#10;-51 -121 -52 -27 -115 -8 -137 41 -17 37 -15 130 4 166 24 47 80 62 133 35z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>O</title>
  </path>
  <path d="M550 1526 l0 -176 90 0 c136 0 180 25 180 103 0 26 -7 41 -29 59&#10;l-28 24 24 29 c29 33 26 77 -7 110 -17 17 -37 21 -125 23 l-105 4 0 -176z&#10;m188 112 c19 -19 14 -55 -10 -72 -13 -9 -43 -16 -70 -16 l-48 0 0 50 0 50 58&#10;0 c32 0 63 -5 70 -12z m-8 -141 c32 -16 40 -65 14 -84 -10 -7 -44 -13 -76 -13&#10;l-58 0 0 55 0 55 48 0 c26 0 58 -6 72 -13z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>B</title>
  </path>
  <path d="M891 1530 c-33 -94 -61 -172 -61 -175 0 -3 13 -5 30 -5 26 0 31 5 41&#10;40 l12 41 70 -3 70 -3 12 -35 c10 -29 17 -36 45 -38 24 -3 31 0 28 10 -3 7&#10;-31 86 -63 176 l-58 162 -32 0 -33 0 -61 -170z m119 31 c11 -37 20 -71 20 -75&#10;0 -3 -20 -6 -45 -6 -25 0 -45 4 -45 9 0 14 40 141 45 141 2 0 13 -31 25 -69z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>A</title>
  </path>
  <path d="M1170 1525 l0 -175 30 0 30 0 0 65 0 65 30 0 c25 0 33 -8 65 -65 32&#10;-57 40 -65 66 -65 16 0 29 3 29 6 0 3 -16 33 -35 65 -19 33 -35 62 -35 64 0 1&#10;16 19 35 38 26 26 35 44 35 69 0 74 -53 108 -170 108 l-80 0 0 -175z m172 108&#10;c21 -19 23 -52 6 -75 -7 -9 -36 -19 -65 -23 l-53 -7 0 61 0 61 47 0 c29 0 53&#10;-6 65 -17z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>R</title>
  </path>
  <path d="M1720 1525 l0 -175 110 0 110 0 0 25 c0 25 -1 25 -79 25 -88 0 -94 5&#10;-84 64 l6 36 73 0 c72 0 74 1 74 25 0 24 -2 25 -75 25 l-75 0 0 50 0 50 80 0&#10;c79 0 80 0 80 25 l0 25 -110 0 -110 0 0 -175z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>E</title>
  </path>
  <path d="M1980 1525 l0 -175 110 0 110 0 0 25 c0 25 -1 25 -80 25 l-80 0 0&#10;150 0 150 -30 0 -30 0 0 -175z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>L</title>
  </path>
  <path d="M2230 1525 l0 -175 49 0 c46 0 48 1 43 25 -2 14 -11 25 -18 25 -11 0&#10;-14 29 -14 150 l0 150 -30 0 -30 0 0 -175z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>L</title>
  </path>
  <path d="M3090 1525 l0 -175 32 0 31 0 -6 137 -6 137 44 -109 c25 -61 45 -111&#10;45 -112 0 -2 13 -3 30 -3 25 0 32 6 50 48 12 26 31 75 43 110 12 34 24 62 27&#10;62 3 0 4 -61 2 -135 l-3 -135 31 0 31 0 -3 175 -2 175 -37 0 -36 0 -46 -110&#10;c-26 -60 -47 -116 -47 -125 0 -9 -4 -13 -10 -10 -5 3 -10 12 -10 20 0 8 -21&#10;62 -46 120 -46 103 -47 105 -80 105 l-34 0 0 -175z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>M</title>
  </path>
  <path d="M2400 1462 c-82 -41 -53 -162 40 -162 47 0 78 23 86 64 8 44 -2 73&#10;-29 90 -38 24 -61 26 -97 8z" 
fill="rgb(6, 127, 174)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>.</title>
  </path>
  <path d="M2560 1382 c0 -27 4 -31 38 -37 21 -3 60 -1 86 6 41 10 46 14 40 33&#10;-9 28 -7 28 -43 15 -31 -11 -69 -9 -103 6 -15 6 -18 2 -18 -23z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>C</title>
  </path>
  <path d="M1647 1078 c-30 -57 -52 -106 -50 -110 2 -5 53 -8 112 -8 118 0 126&#10;-3 137 -58 l7 -32 -162 0 -161 0 0 56 c0 31 -7 71 -16 89 -29 61 -117 83 -179&#10;45 -43 -27 -59 -67 -53 -136 7 -78 44 -115 122 -122 45 -4 56 -8 56 -22 0 -30&#10;-29 -44 -87 -43 l-54 1 3 -31 3 -32 73 0 c71 0 75 1 103 33 18 20 29 43 29 62&#10;l0 30 203 0 203 0 1 35 c1 24 -3 35 -13 35 -9 0 -14 12 -14 35 0 83 -51 125&#10;-150 125 -33 0 -60 4 -60 8 0 4 16 38 36 75 l35 67 -34 0 c-34 0 -37 -3 -90&#10;-102z m-197 -87 c5 -11 10 -42 10 -70 l0 -51 -36 0 c-59 0 -74 14 -74 68 0 27&#10;5 53 12 60 20 20 76 15 88 -7z" 
fill="rgb(6, 127, 174)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>كو</title>
  </path>
  <path d="M2260 1037 c0 -157 -3 -167 -57 -167 -31 0 -33 -2 -33 -35 0 -33 2&#10;-35 35 -35 20 0 48 6 63 14 24 12 30 12 47 0 27 -20 233 -20 260 0 17 12 23&#10;12 47 0 35 -18 79 -18 103 0 17 12 23 12 47 0 54 -28 124 -8 146 41 7 14 12&#10;68 12 120 l0 95 -30 0 -30 0 0 -81 c0 -92 -10 -119 -45 -119 -32 0 -45 27 -45&#10;94 0 56 0 56 -30 56 -30 0 -30 0 -30 -56 0 -66 -13 -94 -43 -94 -37 0 -47 19&#10;-47 87 l0 63 -30 0 c-30 0 -30 0 -30 -56 0 -90 -5 -94 -118 -94 -63 0 -102 4&#10;-110 12 -8 8 -12 57 -12 155 l0 143 -35 0 -35 0 0 -143z" 
fill="rgb(6, 127, 174)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>سلـ</title>
  </path>
  <path d="M3192 1009 c3 -166 4 -171 27 -190 18 -15 39 -19 100 -19 117 0 121&#10;5 121 146 l0 114 -30 0 -30 0 0 -82 c0 -46 -4 -89 -9 -96 -5 -9 -25 -12 -57&#10;-10 l-49 3 -3 153 -3 152 -35 0 -35 0 3 -171z" 
fill="rgb(6, 127, 174)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>با</title>
  </path>
 <path d="M993 1046 c-33 -30 -37 -39 -41 -95 -4 -55 -8 -63 -31 -76 -40 -20&#10;-51 -55 -51 -155 l0 -90 35 0 35 0 0 90 c0 72 3 92 15 96 8 4 21 2 28 -4 7 -6&#10;33 -16 59 -22 91 -23 152 19 163 113 13 106 -40 177 -132 177 -35 0 -50 -6&#10;-80 -34z m136 -70 c31 -66 -21 -141 -81 -117 -26 10 -28 15 -28 62 0 64 20 91&#10;64 87 23 -2 35 -11 45 -32z" 
fill="rgb(6, 127, 174)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>م</title>
  </path>
  <path d="M3050 917 c0 -127 -2 -146 -18 -160 -10 -10 -28 -17 -40 -17 -19 0&#10;-22 -6 -22 -36 0 -36 0 -37 36 -31 95 16 114 57 114 247 l0 140 -35 0 -35 0 0&#10;-143z" 
fill="rgb(6, 127, 174)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>ر</title>
  </path>
  <path d="M2009 905 c-78 -42 -40 -165 51 -165 33 0 80 53 80 90 0 22 -9 41&#10;-29 61 -32 32 -62 36 -102 14z" 
fill="rgb(243, 101, 35)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)">
    <title>.</title>
  </path>
  <path d="M3360 716 c-19 -23 -8 -61 18 -64 46 -7 72 47 33 68 -26 14 -36 13&#10;-51 -4z" 
fill="rgb(6, 127, 174)" transform="matrix(0.10000000149011612, 0, 0, -0.10000000149011612, -7.381295204162601, 321.3356018066406)"/>
</SvgIcon>
  );
}

export function FacebookIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.68 15.92C2.88 15.24 0 11.96 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 11.96 13.12 15.24 9.32 15.92L8.88 15.56H7.12L6.68 15.92Z"
          fill="url(#paint0_linear_795_116)"
        />
        <path
          d="M11.12 10.2391L11.48 7.99914H9.36V6.43914C9.36 5.79914 9.6 5.31914 10.56 5.31914H11.6V3.27914C11.04 3.19914 10.4 3.11914 9.84 3.11914C8 3.11914 6.72 4.23914 6.72 6.23914V7.99914H4.72V10.2391H6.72V15.8791C7.16 15.9591 7.6 15.9991 8.04 15.9991C8.48 15.9991 8.92 15.9591 9.36 15.8791V10.2391H11.12Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_795_116"
            x1="8"
            y1="0"
            x2="8"
            y2="15.9991"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1AAFFF" />
            <stop offset="1" stopColor="#0163E0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export function GoogleIcon() {
  return (
    <SvgIcon>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.68 8.18182C15.68 7.61455 15.6291 7.06909 15.5345 6.54545H8V9.64364H12.3055C12.1164 10.64 11.5491 11.4836 10.6982 12.0509V14.0655H13.2945C14.8073 12.6691 15.68 10.6182 15.68 8.18182Z"
          fill="#4285F4"
        />
        <path
          d="M8 16C10.16 16 11.9709 15.2873 13.2945 14.0655L10.6982 12.0509C9.98545 12.5309 9.07636 12.8218 8 12.8218C5.92 12.8218 4.15273 11.4182 3.52 9.52727H0.858182V11.5927C2.17455 14.2036 4.87273 16 8 16Z"
          fill="#34A853"
        />
        <path
          d="M3.52 9.52C3.36 9.04 3.26545 8.53091 3.26545 8C3.26545 7.46909 3.36 6.96 3.52 6.48V4.41455H0.858182C0.312727 5.49091 0 6.70545 0 8C0 9.29455 0.312727 10.5091 0.858182 11.5855L2.93091 9.97091L3.52 9.52Z"
          fill="#FBBC05"
        />
        <path
          d="M8 3.18545C9.17818 3.18545 10.2255 3.59273 11.0618 4.37818L13.3527 2.08727C11.9636 0.792727 10.16 0 8 0C4.87273 0 2.17455 1.79636 0.858182 4.41455L3.52 6.48C4.15273 4.58909 5.92 3.18545 8 3.18545Z"
          fill="#EA4335"
        />
      </svg>
    </SvgIcon>
  );
}