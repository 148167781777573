export const orderStatus =[
    "Waiting For Tracking No",
    "Waiting To Receive it",
    "on China Wearhouse",
    "Paid",
    "on Shipping Way",
     "on Local Wearhouse",
     "Client Receive it",
     "Refound it",
     "Unkown"


];
export const simpleFormatDate = (dt)=>{
    let m = new Date(dt);
    return m.getDate() +' , '+m.toLocaleString('en', { month: 'short' })+' '+ m.getFullYear()
  
  }