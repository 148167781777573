import React, { useState, useEffect } from 'react';
import { Button,IconButton,Toolbar, Container,AppBar, Grid2, Paper, Typography, ToggleButton, ToggleButtonGroup, Box,Checkbox } from '@mui/material';
import {Widgets,LocalShipping,ArrowBack, CheckBox} from '@mui/icons-material';

// Sample data structure for orders
const orders = [
  { id: 1, code: 'BAR-A-1611', type:"Toner", reference: '74-1', transport: 'AIR' },
  { id: 2, code: 'BAR-A-1612', type:"Normal", reference: '74-2', transport: 'SEA' },
  { id: 3, code: 'BAR-A-1613', type:"Normal",reference: '74-3', transport: 'AIR' },
  { id: 1, code: 'BAR-A-1611', type:"Cosmastic", reference: '74-1', transport: 'AIR' },
  { id: 2, code: 'BAR-A-1612', type:"Battaries", reference: '74-2', transport: 'SEA' },
  { id: 3, code: 'BAR-A-1613', type:"Special", reference: '74-3', transport: 'AIR' },
  { id: 1, code: 'BAR-A-1611', type:"Normal", reference: '74-1', transport: 'AIR' },
  { id: 2, code: 'BAR-A-1612', type:"Toner", reference: '74-2', transport: 'SEA' },
  { id: 3, code: 'BAR-A-1613', type:"Normal", reference: '74-3', transport: 'AIR' },
  { id: 1, code: 'BAR-A-1611', type:"Normal", reference: '74-1', transport: 'AIR' },
  { id: 2, code: 'BAR-A-1612', type:"Normal", reference: '74-2', transport: 'SEA' },
  { id: 3, code: 'BAR-A-1613', type:"Normal", reference: '74-3', transport: 'AIR' },
  // Add more orders as needed
];

const OrderList = () => {
  const [selectedTransport, setSelectedTransport] = useState('AIR');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  useEffect(() => {
    // Filter orders by selected transport method
    setFilteredOrders(orders.filter(order => order.transport === selectedTransport));
  }, [selectedTransport]);

  return (
    <>
       <AppBar position="static" sx={{ backgroundColor: '#FF6A00' }}>
        <Toolbar>
        <IconButton>  <ArrowBack  sx={{fill:"white"}}/></IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Searching Order
          </Typography>

        </Toolbar>
      </AppBar>

    <Container>
      {/* Top AppBar */}
   
      <ToggleButtonGroup
          value={selectedTransport}
          exclusive
          onChange={(e, newTransport) => setSelectedTransport(newTransport)}
          aria-label="text alignment"
          sx={{textAlign:'center',mt:2,display:'block'}}
        >
          <ToggleButton value="AIR" aria-label="left aligned">
            AIR
          </ToggleButton>
          <ToggleButton value="SEA" aria-label="right aligned">
            SEA
          </ToggleButton>
        </ToggleButtonGroup>
    

      <Grid2 container spacing={2} sx={{ marginTop: 2 }}>
        {filteredOrders.map((order) => (
          <Grid2 item size={12} key={order.id}>
            <Paper sx={{ display: 'flex', alignItems: 'center', padding: 2, border: '1px solid orange' }}>
            <Checkbox {...label} defaultChecked />

              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6">{order.code}</Typography>
                <Typography variant="subtitle1" color="textSecondary">{order.reference}</Typography>
                

              </Box>
              <Box >
              <Typography variant='span' className='badge bg-info text-white'>{order.type}</Typography>
              <IconButton variant='i' sx={{borderRadius:2}} className=' bg-success text-white fa fa-plane'></IconButton>
              </Box>
            </Paper>
          </Grid2>
        ))}
      </Grid2>
    </Container>
    </>
  );
};

export default OrderList;
