import React, { useEffect, useState } from 'react';
import {Paper,TableContainer,Table,TableCell,InputLabel,Select,MenuItem,LinearProgress,TableBody,TableHead,TableRow, AppBar,Modal, Toolbar,Button, Typography,List, Container, Grid2, TextField, Box, FormControl, ImageList, ListItem ,IconButton, Divider, ImageListItem, Icon} from '@mui/material';
import {AddPhotoAlternate ,DeleteForever , Clear , ArrowDropDownCircle} from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast ,Bounce } from 'react-toastify';
import { useParams } from 'react-router-dom';
import LanguageSelector from './components/LanguageSelector';
import { apiUploadImage, chkOrder, upNewOrder } from './api';
import Notify from './components/Notify';
import zIndex from '@mui/material/styles/zIndex';

const SellerOrder= ()=>{
    let { clientID } = useParams();
    const scrollToBottom = () => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      };
      const buttonStyle = {
        position: 'fixed',
        bottom: '30px',
        right: '2px',
        fontSize: '16px',
        cursor: 'pointer',
        zIndex:9999,
        color:' var(--bs-primary)'
      };
    const { t , i18n } = useTranslation();
    const [ isAllowed , setIsAllowed ] = useState(true);
    const [errors ,  setErrors] = useState({});
    const [ errorNot , setErrorNot]= useState();
    const [order,setOrder] = useState( { boxes:[{  boxId : 0, trackNo:"", items :[ ] }] } );
    const [open , setOpen] = useState(false);
    const [boxItem , setBoxItem]=useState( { productName :"", pices:'', price:'' });



    const handleBoxDimChange = (index, e) => {
      const { name, value } = e.target;
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index].boxDim = {
        ...updatedBoxes[index].boxDim,
        [name]: value,
      };
      setOrder({ ...order, boxes: updatedBoxes });
    };
    const handleItemsChange = (index, e) => {
      const { name, value } = e.target;
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index].items = {
        ...updatedBoxes[index].items,
        [name]: value,
      };
      setOrder({ ...order, boxes: updatedBoxes });
    };
    const handleOpen = ()=>{
      if(validateForm())
      setOpen(!open);
    }
    const handleBoxChange = (index, e) => {
      const { name, value } = e.target;
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index] = { ...updatedBoxes[index], [name]: value };
      setOrder({ ...order, boxes: updatedBoxes });
    };
  
    const handleAddItem =(index)=>{
      if(!boxItem.pices && !boxItem.productName && !boxItem.price)return;
      const updatedBoxes = [...order.boxes];

      updatedBoxes[index].items = [
        ...updatedBoxes[index].items,
      { pices:boxItem.pices,
        productName:boxItem.productName,
        price:boxItem.price}
      ];
      setOrder({ ...order, boxes: updatedBoxes });
      setBoxItem({productName :"", pices:'', price:'' });

    }
    const handleDeleteItem =(index,_i)=>{
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index].items.splice(_i,1)
      updatedBoxes[index].items = [
        ...updatedBoxes[index].items
      ];
      setOrder({ ...order, boxes: updatedBoxes });

    }
    const handleDeletePhoto =(e,index,_i)=>{
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index].photos.splice(_i,1)
      updatedBoxes[index].photos = [
        ...updatedBoxes[index].photos
      ];
      setOrder({ ...order, boxes: updatedBoxes });

     }
     const handleAddBox = () => {
      const newBox = {
        boxId: order.boxes.length + 1, // Generate new boxId based on the current length
        trackNo: "",
        boxDim: {
          w: '',
          h: '',
          l: ''
        },
        weight: '',
        photos: [],
        items: []
      };
    
      setOrder({
        ...order,
        boxes: [...order.boxes, newBox]
      });
    };
    const handleRemoveBox = (index) => {
      const updatedBoxes = [...order.boxes]; // Copy the current boxes
      updatedBoxes.splice(index, 1); // Remove the box at the specified index
    
      setOrder({
        ...order,
        boxes: updatedBoxes // Update the order with the new boxes array
      });
    };

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;
  
    // Check vendorName and vendorPhone

  
    // Check if all boxes are valid
    order.boxes.forEach((box, index) => {
      if (!box.trackNo.trim()) {
        tempErrors[`trackNo_${index}`] = t(`Tracking No for box `)+ (index + 1)+t(" is required");
        tempErrors[`trackNo_${index}`] = t("field is required");

        isValid = false;
      }
  
    
      if (box.items.length <= 0) {
        tempErrors[`items_${index}`] = t("At least add one product for box ")+(index + 1);
        tempErrors[`items_${index}`] = t("field is required");

        isValid = false;
      }
   
    });
  
    setErrors(tempErrors);
    return isValid;
  };
  const sendOrder = async()=>{
    try {
      let res = await upNewOrder(clientID,order);
      handleOpen()
      setIsAllowed(false);
      setErrorNot("Thank You , We Are Waiting To Your Package");
      
    } catch (error) {
      toast.error(error.response.data.message || error.message);

    }

  }
 
    return <>
    <div dir={(i18n.language == 'ar')?"rtl":"ltr"} lang={i18n.language} key={"Seller_Screen"} >
      <AppBar position="static" className='bg-barsell'>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
          {t("Order Details")} 
          <LanguageSelector />
          </Typography>
        </Toolbar>
      </AppBar>
      <LinearProgress color='warning'/>

      <Container sx={{ mt: 2  , pb:5,mb:2}} >
      {
        !isAllowed &&
      
        <Box sx={{ mt: 5, textAlign: 'center' }} >
          {errorNot || <LinearProgress/>}
      
        </Box>
      }
       {
        isAllowed &&
        <>
      
        <Box>
        <Grid2 container spacing={2}>
       
          <Grid2 item size={12} className="text-center" sx={{  fontFamily: 'monospace' }}>
            BAR-A/S-{clientID}
          </Grid2>
        </Grid2>
        <Box>
   
        </Box>
        <Divider/>

        { order.boxes?.map((e,i)=>{
          console.log(`Index I is ${i}`);
          
          return   <Box key={"box_"+i}>
 <Grid2 container spacing={2} sx={{ mt: 2 }}>
 <Typography variant='h5' align='center' sx={{mt:2,borderBottom:"2px solid #ed6c0266"}}> {t("Box")} {i+1 }</Typography>
 <IconButton sx={{    ml: (i18n.language != 'ar')? "auto":"unset" , mr:(i18n.language == 'ar')? "auto":"unset" }} onClick={e=>handleRemoveBox(i)} color='error' ><DeleteForever  /></IconButton>


 </Grid2>

 <Grid2 container spacing={2} sx={{ mt: 2 ,mb:5}}>

  
   <Divider/>
       <Grid2 item size={12}>
       <TextField variant="outlined" type="text" 
   name="trackNo" value={e.trackNo}  
   error={!!errors[`trackNo_${i}`]}
   helperText={errors[`trackNo_${i}`] || ''} 
   onChange={e=>handleBoxChange(i,e)} fullWidth label={t("Tracking NO")}/>
       </Grid2>
       <Divider/>

   
   <Grid2 item size={12}>
   
   <FormControl fullWidth>
        <TextField
        variant="outlined" type="text" value={boxItem.productName} 
        onChange={e=>{
         setBoxItem({...boxItem,productName:e.target.value});
         
       }} 
        fullWidth label={t("Product Name")} />
      </FormControl>
   </Grid2>
   <Grid2 item size={4}>
   <TextField variant="outlined" type="number" 
   value={boxItem.pices} onChange={e=>setBoxItem({...boxItem,pices:e.target.value})} 
   fullWidth label={t("Quantity")}/>
   
   </Grid2>
   <Grid2 item size={4}>
   <TextField variant="outlined" type="number" value={boxItem.price} 
   onChange={e=>{
    setBoxItem({...boxItem,price:e.target.value});
    
  }} 
   fullWidth label={t("Unit Price")}/>

   </Grid2>
   <Grid2 item size={4} className="text-center">
    {(boxItem.pices && boxItem.price) &&    <span className='d-block'>{boxItem.pices}x{boxItem.price}</span>}

   <Button variant='outlined' color='primary'  onClick={e=>handleAddItem(i)}>{t("Add")}</Button>
   </Grid2>
   <Grid2 size={12}>
     <List sx={{ width: '100%', maxWidth: 450,m:"auto"}}>
     <ListItem sx={{borderRadius:"10px",px:"5px",m:1}}  key={i+"_start"} >
             <Grid2 container size={12} > 
               <Grid2 size={3}>{t("Name")}</Grid2>
               <Grid2 size={3}>{t("Quantity")}</Grid2>
               <Grid2 size={3}>{t("Price")}</Grid2>
               <Grid2 size={3}>{t("Total")}</Grid2>


             </Grid2>
           </ListItem>
       {
         e.items?.map((e,_i)=>
          <> <ListItem sx={{bgcolor:'#ebeaea',borderRadius:"10px",px:"5px",m:1}}  key={i}
           disableGutters
           secondaryAction={
             <IconButton aria-label="comment">
               <DeleteForever onClick={(e)=>handleDeleteItem(i,_i)} />
             </IconButton>
           }>
             <Grid2 container size={12} > 
               <Grid2 size={3}>{e.productName}</Grid2>
               <Grid2 size={3}>{e.pices}</Grid2>
               <Grid2 size={3}>{e.price}</Grid2>
               <Grid2 size={3}>{e.price * e.pices}</Grid2>

             </Grid2>
           </ListItem>
           </>
           
         )
       }
       <ListItem sx={{borderRadius:"10px",px:"5px",m:1}}  key={"_start"} >
             <Grid2 container size={12} > 
               <Grid2 size={4}>{t("Total")}</Grid2>
               <Grid2 size={4}>{e.items?.reduce((total, item) => total + Number(item.pices * item.price), 0)}{e?.currency || "USD"}</Grid2>

             </Grid2>
           </ListItem>
     </List>
   </Grid2>
 </Grid2>
 </Box>
 
  })}
       
       
        </Box>
        <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        display: 'flex',
        justifyContent: 'space-around', // Spread the buttons out evenly
        padding: '16px',
        zIndex: 1000, // Ensure it's above other elements
      }}
    >
      {/* Buttons */}
      <Button variant="contained" color="warning" onClick={handleAddBox}>
        { (order.boxes.length > 0) && <>{t("Add More")}</> }
        { (order.boxes.length == 0) && <>{t("Add Box")}</> }

      </Button> 
      {
        order.boxes.length > 0 &&  <Button variant="contained" color="primary" onClick={handleOpen}>
        {t("Send Package")}
      </Button>
      }
   
   
    </Box>
        </>
            }
      </Container>
      <Modal
  open={open}
  onClose={handleOpen}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,}}>
    <Typography  id="modal-modal-title" variant="h6" component="h2">
    {t("Packaging Info")} 
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    {order.boxes.map((bx,i) =>
   <Box key={"order_boxes_map_"+i}>

   <Typography>{i+1}-{t("Box")}    </Typography>
  <TableContainer component={Paper}>
        <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("Product Name")}</TableCell>
            <TableCell align="right">{t("Price")}</TableCell>
            <TableCell align="right">{t("Quantity")}</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
         
        {
            bx.items.map((row)=>
              <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.productName}
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">{row.pices}</TableCell>
         
            </TableRow>
            )
           
}
        </TableBody>
      </Table>
    </TableContainer>
   
    </Box>
    )}
     <Typography variant='small' align='center' color='error'>*{t("By clicking Send you agree to our business policy, please make sure that the information above is correct, or you will be held responsible.")}</Typography>
     <Grid2 container >
      <Grid2 item size={6}>
      <Button variant='contained' color='info' onClick={handleOpen} >{t("Back")}</Button>

      </Grid2>
      <Grid2 item size={6} sx={{display:"flex"}}>

     <Button variant='contained' color='warning' sx={{ml:'auto'}} onClick={sendOrder}>{t("Submit")}</Button>
     </Grid2>

     </Grid2>
    </Typography>
  </Box>
</Modal>
    </div>
    <IconButton onClick={scrollToBottom} style={buttonStyle} >
      <ArrowDropDownCircle sx={{width:45,height:45}} />
    </IconButton>
    </>;
}
export default SellerOrder;