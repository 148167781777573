import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Calculator from './Calculator';
import Seller from './Seller';
import { ThemeProvider  , createTheme} from '@mui/material';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import {Helmet} from "react-helmet";
import Wearhouse from './Wearhouse';
import Receive from './wearhouse/Receive';
import SellerOrder from './SellerOrder';
import Trc from './Trc';
import { BorderColor } from '@mui/icons-material';
import Order from './wearhouse/Order';
import Test from './widgets/Test';
import OrderList from './wearhouse/Toship';
const theme = createTheme({

components:{
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        scrollbarColor:"#007 #bada55",
        scrollbarWidth: "thin",
        "scrollbar-colors":"#007 #bada55",
        '&::-webkit-scrollbar': {
          width: '10px', // Scrollbar width
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#ff9800', // Scrollbar thumb color
          borderRadius: '10px',
          border: '2px solid #ffffff', // Thumb border (optional)
        
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#eeeeee', // Scrollbar track color
        },
      },
    },
  },
  MuiTypography:{
    styleOverrides:{
      root: {
        '&.MuiTypography-h6 , &.MuiTypography-body1': {
            fontFamily: "'Roboto','Helvetica','Arial',sans-serif ",
          
          // Ensuring the CSS specificity works for `dir="html"`
          '[dir="rtl"] &': {
           
            fontFamily: "'Cairo', Arial, sans-serif  ",
          },
        },
        '&.MuiTypography-subtitle2 ,  &.MuiTypography-body2': {
    
            fontFamily: "'Roboto','Helvetica','Arial',sans-serif ",
          
          // Ensuring the CSS specificity works for `dir="html"`
          '[dir="rtl"] &': {
            fontFamily: "'Tajawal', Arial, sans-serif  ",
          },
          
        }
        
      }
    }
  },
  MuiTextField:{
    styleOverrides:{
      root: {
        '& .MuiInputBase-input': {
          fontFamily:`Roboto,Helvetica,Arial,sans-serif` ,
           '[dir="rtl"] &': {
            fontFamily: "'Tajawal', Arial, sans-serif",
          } 
        },
        '& .MuiFormLabel-root': {
          fontFamily:"tajawal,Arial,sans-serif", // Custom font family for the label
        },
    }

  },

  },
  MuiListItem:{
    variants: [
      {
        props: { variant: "tracking" }, style:{
          ".MuiListItemText-root":{
     
            marginRight:"8px",
            textAlign:"right",
           
           },
            
            
            "&::before":{
              content:'" "',
              height: "68px",
              position: "absolute",
              bottom: "-42px",
              borderLeft: "3px dashed #d3d3d3",
              right: "36px"
            },
            "&.active::before":{
                borderColor:"#198754"
              }
              , "&:last-child::before":{
              content:"unset",
              height: "0",
              
            }
        },
     
    }]
    },

  },

});
const router = createBrowserRouter([
  {
    path: "/",
    element: 
    <App />,
  },
  {
    path: "/calculator",
    element: 
    <ThemeProvider theme={theme}><Calculator /></ThemeProvider>
    ,
  },{
    path: "/:clientID/new-order",
    element:<ThemeProvider theme={theme}>
    <SellerOrder /></ThemeProvider> ,
  },{
    path: "/seller/:orderId/:tcorder",
    element:<ThemeProvider theme={theme}>
    <Seller /></ThemeProvider> ,
  },{
    path: "/seller/:ad/:orderId/:tcorder",
    element:<ThemeProvider theme={theme}>
    <Seller /></ThemeProvider> ,
  },{
    path: "/trc/:orderId",
    element:<ThemeProvider theme={theme}>
    <Trc /></ThemeProvider> ,
  },{
    path: "/wearhouse",
    element:<ThemeProvider theme={theme}>
    <Wearhouse /></ThemeProvider> ,
  },
  {
    path: "/wearhouse/receive",
    element:<ThemeProvider theme={theme}>
    <Receive /></ThemeProvider> ,
  },{
    path: "/wearhouse/toship",
    element:<ThemeProvider theme={theme}>
    <OrderList /></ThemeProvider> ,
  },
  {
    path: "/wearhouse/receive/:orderId",
    element:<ThemeProvider theme={theme}>
    <Order /></ThemeProvider> ,
  },
  {
    path: "/test",
    element:<ThemeProvider theme={theme}>
    <Test /></ThemeProvider> ,
  }
]);
console.log(i18n.language);
console.log((i18n.language == 'ar')?"rtl":"ltr");


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

        <RouterProvider router={router} />
        <ToastContainer />
  </React.StrictMode>
);

reportWebVitals();
