import React, { useEffect, useState } from 'react';
import { Paper, Grid2, Typography, List, ListItem, ListItemText, Box, Avatar, Button } from '@mui/material';
import {AccessTime,CheckCircle,Payment,ShoppingCart,Person4,Error} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { trackingApi } from './api';
import Notify from './components/Notify';
const ArabicDate=(dt = '')=> {
  // Define the Arabic month names
  const months = {
      "Jan": "يناير",
      "Feb": "فبراير",
      "Mar": "مارس",
      "Apr": "أبريل",
      "May": "مايو",
      "Jun": "يونيو",
      "Jul": "يوليو",
      "Aug": "أغسطس",
      "Sep": "سبتمبر",
      "Oct": "أكتوبر",
      "Nov": "نوفمبر",
      "Dec": "ديسمبر"
  };

  // Get the current date
  
  let currentDate = dt!== '' ?new Date(dt):new Date();
  
  // Get the English month abbreviation
  let enMonth = currentDate.toLocaleString('en', { month: 'short' });
  let arMonth = months[enMonth]; // Get the Arabic equivalent

  // Define the Arabic day names
  const days = {
      "Sat": "السبت",
      "Sun": "الأحد",
      "Mon": "الإثنين",
      "Tue": "الثلاثاء",
      "Wed": "الأربعاء",
      "Thu": "الخميس",
      "Fri": "الجمعة"
  };

  // Get the English day abbreviation
  let enDay = currentDate.toLocaleString('en', { weekday: 'short' });
  let arDay = days[enDay]; // Get the Arabic equivalent

  let today = new Date();

  // Format the date as: Day DayNumber / Month / Year
  let dayNumber = currentDate.getDate().toString();
  let year = (currentDate.getFullYear()==today.getFullYear())?"":currentDate.getFullYear().toString();
  let arabicDate = `${arDay} , ${dayNumber}  ${arMonth}  ${year}`;

  return arabicDate;
}

// Example usage


const Trc = () => {
    let { orderId, tcorder } = useParams();
    const [isAllowed ,setIsAllowed] = useState(false);
    const [order , setOrder] = useState({});
    const [status , setStatus] = useState([]);

    
    const checkOrder=async()=>{
      try{
        let res =  await trackingApi(orderId);
        console.log(res);
      if(!res.data.data.Details){
        setIsAllowed(true);
        setOrder(res.data.order);
        setStatus(res.data.data);
      }
   
      else setErrorNot(t("Already Subimted We Are Waiting To Receive it in Our Wearhouse"));
      
      }catch(err){
        Notify({message:err.message,type:"error"})
      setIsAllowed(false);

      }
    }
    useEffect(()=>{
      checkOrder();
    },[]);
  return (
    <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: 'auto' }}>
                <Helmet  htmlAttributes={{ dir:"rtl", lang:"ar"}} />

      {/* Header Section */}
      
      <Typography variant="h6" gutterBottom>تتبع الشحنة</Typography>
      <Grid2 container size={12}>
      <Grid2 item size={6}>
      <Typography variant="subtitle2" color="textSecondary">{ArabicDate(order?.odate)}</Typography>
      <Typography variant="subtitle2">رقم الطلب: {orderId}</Typography>
      </Grid2>
      <Grid2 item size={6} sx={{textAlign:"left"}}>

      <Typography variant="h5" color="primary" sx={{ marginTop: 2 ,display:"flex",justifyContent:"end" }}><Typography>{order?.currency || "OMR"}</Typography>  99.99 </Typography>

      </Grid2>

      </Grid2>
      
      {/* Status List */}
      <List variant='tracking'>
        {status.map(e=><>
          <ListItem variant='tracking' className='active'>
          <Grid2 container alignItems="center">
            <Grid2 item size={2}>
              <Avatar sx={{ bgcolor: 'green' }}>
                <CheckCircle />
              </Avatar>
            </Grid2>
            <Grid2 item size={10}>
              <ListItemText 
                primary={e?.title}
                secondary={e?.description}
                
              />
            </Grid2>
          </Grid2>
        </ListItem>
        
        
        </>)}
    
        <ListItem variant='tracking'>
          <Grid2 container alignItems="center">
            <Grid2 item size={2}>
              <Avatar sx={{ bgcolor: 'green' }}>
                <CheckCircle />
              </Avatar>
            </Grid2>
            <Grid2 item size={10} >
              <ListItemText
                primary="تمت أضافة رقم التتبع الداخلي "
                secondary="بأنتظار وصول الشحنه لمستودعنا"
              />
            </Grid2>
          </Grid2>
        </ListItem>
        <ListItem variant='tracking'>
          <Grid2 container alignItems="center">
            <Grid2 item size={2}>
              <Avatar sx={{ bgcolor: 'lightcoral' }}>
                <Error />
              </Avatar>
            </Grid2>
            <Grid2 item size={10}>
              <ListItemText
                primary="فشل في وصول الشحنه"
                secondary="تواصل معا البائع للتحقق من ذلك"
              />
            </Grid2>
          </Grid2>
        </ListItem>
        <ListItem variant='tracking'>
          <Grid2 container alignItems="center">
            <Grid2 item size={2}>
              <Avatar sx={{ bgcolor: 'lightgray' }}>
                <ShoppingCart />
              </Avatar>
            </Grid2>
            <Grid2 item size={10}>
              <ListItemText
                primary="وصلت الشحنه مستودعنا "
                secondary="بأنتظار السعر النهائي "
              />
            </Grid2>
          </Grid2>
        </ListItem>

        <ListItem variant='tracking'>
          <Grid2 container alignItems="center">
            <Grid2 item size={2}>
              <Avatar sx={{ bgcolor: 'lightgray' }}>
                <Payment />
              </Avatar>
            </Grid2>
            <Grid2 item size={10}>
              <ListItemText
                primary="الدفع و تحديد طريقة الشحن "
                secondary={<>
                <Grid2 container size={12}>
                  <Grid2 item size={6} alignItems={"center"}> 
                    <Typography variant='subtitle' className='d-block' align='center'>شحن جوي</Typography>
                    <Typography variantMapping='subtitle' sx={{display:'block',m:"auto",position:"relative",width:"max-content"}} align='center' >5 
                      <Typography variant='span' style={{fontSize:"x-small"}}>kg</Typography></Typography>

                     <Button sx={{display:"block",m:"auto"}} variant='contained' >
                      <Typography variant='p'>90<Typography variant='span' style={{fontSize:"x-small"}}>OMR</Typography></Typography>
                      <br/>
                       <Typography variant='subtitle'>دفع</Typography> 
                       </Button>
                     </Grid2>
                  <Grid2 item size={6}> 
                     <Typography variant='subtitle' className='d-block' align='center'>شحن بحري</Typography>
                    <Typography variantMapping='subtitle' sx={{display:'block',m:"auto",position:"relative",width:"max-content"}} align='center' >0.006 
                      <Typography variant='span' style={{fontSize:"x-small"}}>cbm</Typography></Typography>

                     <Button sx={{display:"block",m:"auto"}} variant='contained' >
                      <Typography variant='p'>10<Typography variant='span' style={{fontSize:"x-small"}}>OMR</Typography></Typography>
                      <br/>
                       <Typography variant='subtitle'>دفع</Typography> 
                       </Button>
                  </Grid2>

                </Grid2>
                </>}
              />
            </Grid2>
          </Grid2>
        </ListItem>
        
        <ListItem variant='tracking'>
          <Grid2 container alignItems="center">
            <Grid2 item size={2}>
              <Avatar sx={{ bgcolor: 'lightgray' }}>
                <ShoppingCart />
              </Avatar>
            </Grid2>
            <Grid2 item size={10}>
              <ListItemText
                primary="خروج من المستودع"
                secondary="بأنتظار وصوله إلى عمان "
              />
            </Grid2>
          </Grid2>
        </ListItem>
        <ListItem variant='tracking'>
          <Grid2 container alignItems="center">
            <Grid2 item size={2}>
              <Avatar sx={{ bgcolor: 'lightgray' }}>
                <ShoppingCart />
              </Avatar>
            </Grid2>
            <Grid2 item size={10}>
              <ListItemText
                primary="الشحنة وصلت "
                secondary="بأمكانك أستلام الشحنة من مستودعنا "
              />
            </Grid2>
          </Grid2>
        </ListItem>
        <ListItem variant='tracking'>
          <Grid2 container alignItems="center">
            <Grid2 item size={2}>
              <Avatar sx={{ bgcolor: 'lightgray' }}>
                <ShoppingCart />
              </Avatar>
            </Grid2>
            <Grid2 item size={10}>
              <ListItemText
                primary="تم أستلام الشحنه"
                secondary="نشكرك من الشحن عبرنا "
              />
            </Grid2>
          </Grid2>
        </ListItem>
      </List>

      {/* Delivery Address */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" color="textSecondary">
          عنوان الإستلام
        </Typography>
        <Typography variant="body2">
          عمان - مسقط - سندان 
        </Typography>
      </Box>

      {/* Rating Section */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" color="textSecondary">
          في حال وصول الشحنة سيتم إرسال بريد إليك أو رسالة عبر الواتساب
        </Typography>
        <Typography variant="body2" color="textSecondary">
          بأمكانك الإتصال بخدمة العملاء 
        </Typography>
        {/* Placeholder for star rating */}
      </Box>
    </Paper>
  );
};

export default Trc;
