import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, TextField, Button, Container, Card, CardContent, Grid2, LinearProgress, IconButton } from '@mui/material';
import Notify from '../components/Notify';
import { searchOrder } from '../api';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { Widgets } from '@mui/icons-material';
import { simpleFormatDate, orderStatus } from '../components/Helper';
const Receive = () => {
    const [orders, setOrders]= useState([]);
    const [searchTxt,setSearchTxt] = useState('');
    const [isSearhing,setIsSearching] = useState(false);
    const {t} = useTranslation();
    const fetchOrders= async()=>{
        setIsSearching(true);
        if(searchTxt.length >= 2){  
         try{
            let res = await searchOrder(searchTxt);
            if(res.data.data){
              setOrders(res.data.data);
              console.log(orders);
            } 
          
         }catch(error){

          if(error.status == 401){
            location.href = location.origin+'/wearhouse'

          }
        
            Notify({message:error.response?.data.message || error.message,type:error.response?.status ||error.status})
         }
        }else  Notify({message:"Must be at lest 2 Charcter"})
        setIsSearching(false);

    }
    const handleSearch =()=>{
        fetchOrders();
    }
  return (
    <div>
      {/* Top AppBar */}
      <AppBar position="static" sx={{ backgroundColor: '#FF6A00' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Searching Order
          </Typography>
        <IconButton>  <Widgets  sx={{fill:"white"}}/></IconButton>
        </Toolbar>
      </AppBar>
      <div>
      {/* Search Section */}
      <Container sx={{ mt: 3 }}>
        <Grid2 container justifyContent="center">
          <Grid2 item size={12} sm={8} md={6}>
            <Form onSubmit={handleSearch}>
            <TextField
              fullWidth
              value={searchTxt}
              onChange={(e)=>{setSearchTxt(e.target.value)}}
              variant="outlined"
              placeholder="Product Name, Tracking Number, Client Code, Name"  
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '30px',
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
            </Form>
          </Grid2>
          <Grid2 item>
            <Button
              onClick={handleSearch}
              variant="contained"
              sx={{
                backgroundColor: '#FF6A00',
                color: 'white',
                borderRadius: '20px',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                ml: 2,
                height: '100%',
                px: 4,
              }}
            >
              Search
            </Button>
          </Grid2>
        </Grid2>
      </Container>
        {isSearhing && <LinearProgress/>}
      {/* Results Section */}
      {!isSearhing && 
      <>
      <Container sx={{ mt: 4 }}>
        {orders.length > 0 &&
        <>
        {orders.map((_order, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            
              <Button sx={{width:"100%"}}  href={'receive/'+_order._id} target='_blank'> 
              <CardContent sx={{width:"100%"}}>
              <Grid2 container alignItems="center" >
                <Grid2 item size={3}>
                  <Typography variant="subtitle1" fontWeight="bold" align='left'>
                    #{_order._id}
                  </Typography>
                </Grid2>
                <Grid2 item size={6}>
                  <Typography variant="subtitle1" fontWeight="bold" align='center'>
                   {_order.cname} 
                  </Typography>
                </Grid2>
                <Grid2 item size={3} >
                  <Typography variant="subtitle1" fontWeight="bold" align='right'>
                   BAR-{_order.clientId.id || _order.cid}
                  </Typography>
                </Grid2>
                <Typography variant='span' sx={{
                  position:'absolute',
                  bottom:0,
                  right:10
                }}>
                  {simpleFormatDate(_order.created)}
                </Typography>
                <Typography variant='span' sx={{
                  position:'absolute',
                  bottom:0,
                  left:10
                }}>{_order.Details && 
                  <>{_order.Details.vendorName} ,  {_order.Details.vendorPhone}</>
                }
                </Typography>
                <Grid2 item size={12}>
                    {( Array.isArray(_order.orderDetails))?_order.orderDetails.map((_item , i )=><>
                            <Typography variant="body2" color="textSecondary" align='left'>
                            {(1+i)}.{_item.productName}
                               </Typography>  
                               </>

                                        ):
                                    <Typography variant="body2" color="textSecondary">
                                        {t("Seller Not Add Any Product")}
                                    </Typography>}
                </Grid2>
                
                <Grid2 item size={12}>
                            <Typography variant="body2" color="textSecondary" className='text-danger'  align='right'>
                                    {orderStatus[_order.status]}
                               </Typography>  
                               

                                    
                </Grid2>
              </Grid2>
              </CardContent>
              </Button>
           
          </Card>
        ))}
        </>
        }
        {orders.length < 1 &&
            <>
            <Typography align='center'>{t("No Data Found ")}</Typography>
            </>
        }
      </Container>
      </>
      }
      </div>
    </div>
  );
};

export default Receive;
